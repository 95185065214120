<template>
    <section class="home">
        <div class="position-relative container px-0 px-md-3 d-flex justify-content-center align-items-center h-100">
            <div class="row flex-nowrap h-100 w-100">
                <div class="d-none d-lg-block col-lg-1"></div>
                <div class="headlines col-11 col-md-6 col-lg-6 d-flex flex-column justify-content-center align-items-start">
                    <div class="headline mb-2">
                        <h1 class="font-noto-serif font-weight-bold title mb-0">
                            全球頂尖
                        </h1>
                        <h1 class="font-noto-serif font-weight-bold title mb-0">
                            時光肌
                        </h1>
                        <h1 class="font-noto-serif font-weight-bold title mb-0">
                            修復療程
                        </h1>
                    </div>

                    <h3 class="font-weight-bold text-nowrap title mb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        世界No.1幹細胞權威專家
                    </h3>

                    <h5 class="text-nowrap mb-3"
                        data-aos="fade"
                        data-aos-duration="1000">
                        以科研顛覆傳統 行業先導逆齡專家<br>
                        超過30年研發專利技術
                    </h5>
                    <h5 class="text-nowrap mb-4"
                        data-aos="fade"
                        data-aos-duration="1000">
                        專利突破性技術 抓住青春鑰匙 定格肌齡<br>
                        水潤亮澤、提升緊緻<br>
                        持久美白、減淡細紋
                    </h5>

                    <p class="text-justify mb-4">
                        時光肌®安瓶為首項取得專利利用人體骨髓幹細胞培養液(SCCM)的護膚品，以專利技術於負攝氏196度至200度缺氧技術及無菌安全下研發及培養，以醫學為基礎，科研為本，全面激活細胞，迅速修復及滋養肌膚，讓肌膚無懼時光，時刻擁有再生嫩肌。
                    </p>

                    <div class="price">
                        <h3 class="title font-weight-bold mb-0"
                            data-aos="fade"
                            data-aos-duration="1000">
                            限量5折體驗：
                        </h3>
                        <h1 class="display-3 font-weight-bold title mb-1"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            $600
                        </h1>
                        <h3 class="title font-weight-bold mb-0"
                            data-aos="fade"
                            data-aos-duration="1000">
                            <del>原價：$1,200</del>
                        </h3>
                    </div>
                </div>
                <div class="model col-1 col-md-6 col-xl-5">
                    <img class="h-100 position-absolute"
                         src="../../../assets/campaign/silkpeel/home_model.png"
                         alt="Home Model"
                         data-aos="fade"
                         data-aos-duration="750">
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: "SilkpeelHome",

}
</script>

<style lang="scss" scoped>
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins/_breakpoints";

@import "./mixins.scss";
@import "./variables.scss";

@import url("https://fonts.googleapis.com/css?family=Noto+Serif");

.font-noto-serif {
    font-family: "Noto Serif", serif;
}

.home {
    height: 100vh;

    position: relative;
    background-color: rgba(255, 255, 255, 1);

    background-image: url("../../../assets/campaign/silkpeel/landing2_home_bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;

    overflow-x: hidden;

    .container {
        .model {
            & > img {
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }

    .headlines {
        z-index: 9;

        .headline {
            h1 {
                font-size: 3rem;
                line-height: 3rem;

                @include media-breakpoint-up(sm) {
                    font-size: 3.5rem;
                    line-height: 3.5rem;
                }

                @include media-breakpoint-up(md) {
                    font-size: 4.5rem;
                    line-height: 4.5rem;
                }

                &:first-child,
                &:nth-child(2),
                &:nth-child(3) {
                    color: transparent;
                    background-image: linear-gradient(
                            0deg,
                            rgba(144, 222, 237, 1) 0%,
                            rgba(144, 222, 237, 1) 50%,
                            rgba(232, 248, 251, 1) 100%
                    );
                    -webkit-background-clip: text;
                }
            }
        }

        .price {
            h1 {
                font-size: 3.5rem;
                line-height: 3.5rem;

                @include media-breakpoint-up(sm) {
                    font-size: 5.5rem;
                    line-height: 5.5rem;
                }
            }
        }
    }
}
</style>
