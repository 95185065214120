<template>
    <section class="comparison-1">
        <div class="container py-5">
            <h2 class="mb-4 title text-center"
                data-aos="fade-up"
                data-aos-duration="1000">
                SILKPEEL 臨床實證
            </h2>
            <div class="row pt-5 pb-0"
                 data-aos="fade"
                 data-aos-duration="1000">
                <div class="col-md-6">
                    <h6>去紅印，暗瘡印</h6>
                    <img class="img-fluid" style="width: 100%;"
                         src="../../../assets/campaign/silkpeel/compare1_before_after_1.png"
                         alt="before treatment">
                    <div class="d-flex image-before-after">
                        <div class="col">
                            <p>治療前</p>
                        </div>
                        <div class="col">
                            <p>6星期治療後</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h6>改善油性皮膚</h6>
                    <img class="img-fluid" style="width: 100%;"
                         src="../../../assets/campaign/silkpeel/compare1_before_after_2.png"
                         alt="after treatment">
                    <div class="d-flex image-before-after">
                        <div class="col">
                            <p>治療前</p>
                        </div>
                        <div class="col">
                            <p>10星期治療後</p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="mt-2 mb-0 pb-4">圖片只供參考 療程效果因人而異</p>
        </div>
    </section>
</template>

<script>
export default {
    name: "SilkpeelComparison1",

}
</script>

<style lang="scss" scoped>
@import "./mixins.scss";
@import "./variables.scss";

section {
    &.comparison-1 {
        background-image: url("../../../assets/campaign/silkpeel/landing2_compare1_bg.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;

        & > .container {
            .row {
                & > .col-md-6 {
                    & > .image-before-after {
                        & > .col:first-child {
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }
}
</style>
