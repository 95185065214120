<template>
    <section class="comparison-2">
        <div class="container py-5"
             data-aos="fade"
             data-aos-duration="1000">
            <div class="row pt-5 pb-0">
                <div class="col-md-6">
                    <h6>改善色斑，提亮</h6>
                    <img class="img-fluid" style="width: 100%;"
                         src="../../../assets/campaign/silkpeel/compare2_before_after_1.png"
                         alt="before treatment">
                    <div class="d-flex image-before-after">
                        <div class="col">
                            <p>治療前</p>
                        </div>
                        <div class="col">
                            <p>8星期治療後</p>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <h6>減淡老人斑</h6>
                    <img class="img-fluid" style="width: 100%;"
                         src="../../../assets/campaign/silkpeel/compare2_before_after_2.png"
                         alt="after treatment">
                    <div class="d-flex image-before-after">
                        <div class="col">
                            <p>治療前</p>
                        </div>
                        <div class="col">
                            <p>10星期治療後</p>
                        </div>
                    </div>
                </div>
            </div>
            <p class="mt-2 mb-0 pb-4">
                圖片只供參考 療程效果因人而異
            </p>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SilkpeelComparison2",

    }
</script>

<style lang="scss" scoped>
    @import "./mixins.scss";
    @import "./variables.scss";

    section {
        &.comparison-2 {
            background-image: url("../../../assets/campaign/silkpeel/landing2_compare2_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            & > .container {
                color: rgba(255, 255, 255, 1);

                .row {
                    & > .col-md-6 {
                        & > .image-before-after {
                            & > .col:first-child {
                                padding-left: 0;
                            }
                        }
                    }
                }
            }
        }
    }
</style>
