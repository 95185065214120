<template>
    <section class="features">
        <div class="model">
            <img src="../../../assets/campaign/silkpeel/features_model.png" alt="Feature Model">
        </div>
        <div class="container pt-5">
            <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6">
                    <h1 class="text-center title font-weight-bold mb-5"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        時光肌®淨肌修復激活肌底細胞
                    </h1>
                    <div class="mb-5">
                        <h4 class="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            療程特點
                        </h4>
                        <ul data-aos="fade"
                            data-aos-duration="1000">
                            <li class="mb-3">雙導管獨立同時運作技術；一面淨化排毒一面注入精華，再以醫學級負壓技術進行淋巴引流</li>
                            <li class="mb-3">疏通細胞組織促進血液循環</li>
                            <li class="mb-3">使暗粒黑頭輕易導出，污垢更能即時呈現於污水中</li>
                            <li class="mb-3">針對不同膚質及治療區域，配合不同肌膚需求而使用不同鑽石頭為肌膚作治療之用</li>
                            <li class="mb-3">醫學報告證實，時光肌修復療程可減淡細紋高達10%</li>
                        </ul>
                    </div>
                    <div class="mb-5">
                        <h4 class="mb-3"
                            data-aos="fade-up"
                            data-aos-duration="1000">
                            療程好處
                        </h4>
                        <ul data-aos="fade"
                            data-aos-duration="1000">
                            <li class="mb-3">令暗粒黑頭輕易導出，污垢更能即時呈現於污水中</li>
                            <li class="mb-3">4款原廠高效精華素針對不同肌膚狀況作深層導入</li>
                            <li class="mb-3">以皇牌精華作導入 (SCCM Ampoule)</li>
                            <li class="mb-3">改善暗粒、暗瘡、毛孔、粉刺問題</li>
                            <li class="mb-3">改善膚色不均、皮膚蠟黃問題</li>
                            <li class="mb-3">肌膚變得水潤亮澤、提升緊致、持久美白</li>
                            <li class="mb-3">緊緻肌膚，有效延緩衰老</li>
                        </ul>
                    </div>
                    <div class="mb-5 d-flex flex-row justify-content-center align-items-center">
                        <div class="d-flex flex-column justify-content-center align-items-center ml-4 ml-lg-5 mr-1 mr-lg-4">
                            <img class="img-fluid"
                                 src="../../../assets/campaign/silkpeel/features_icon_1.png" alt="Feature 1"
                                 data-aos="fade-up"
                                 data-aos-duration="1000">
                            <h5 class="text-center font-weight-bold mt-4"
                                data-aos="fade"
                                data-aos-duration="1000">
                                去除角質
                            </h5>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center mx-4">
                            <img class="img-fluid"
                                 src="../../../assets/campaign/silkpeel/features_icon_2.png" alt="Feature 2"
                                 data-aos="fade-up"
                                 data-aos-duration="1000">
                            <h5 class="text-center font-weight-bold mt-4"
                                data-aos="fade"
                                data-aos-duration="1000">
                                深層清潔
                            </h5>
                        </div>
                        <div class="d-flex flex-column justify-content-center align-items-center ml-1 ml-lg-4 mr-4 mr-lg-5">
                            <img class="img-fluid"
                                 src="../../../assets/campaign/silkpeel/features_icon_3.png" alt="Feature 3"
                                 data-aos="fade-up"
                                 data-aos-duration="1000">
                            <h5 class="text-center font-weight-bold mt-4"
                                data-aos="fade"
                                data-aos-duration="1000">
                                精華導入
                            </h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SilkpeelFeatures",

    }
</script>

<style lang="scss" scoped>
    @import "./mixins.scss";
    @import "./variables.scss";

    section {
        &.features {
            min-height: 50vh;

            position: relative;
            background-color: rgba(255, 255, 255, 1);

            & > .model {
                display: none;
                position: absolute;
                left: 0;
                bottom: 0;
                height: 100%;

                @include sm {
                    display: none;
                    left: 0;
                }

                @include md {
                    display: block;
                    left: -15%;
                }

                @include lg {
                    display: block;
                    left: -10%;
                }

                @include xl {
                    display: block;
                    left: 0;
                }

                & > img {
                    height: 100%;
                    width: auto;
                }
            }

            & > .container {

            }
        }
    }
</style>
