<template>
    <section class="methods p-5">
        <div class="container d-flex flex-column justify-content-center align-items-center">
            <h1 class="text-center font-weight-bold mt-2 mb-5"
                data-aos="fade"
                data-aos-duration="1000">
                醫學磨皮療程比較
            </h1>
            <div class="cards row justify-content-center pt-4 pb-5">
                <div class="col-md card mb-4 mb-md-0 mr-md-4 p-0"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <div class="card-body">
                        <div class="card-title text-center featured d-flex flex-column justify-content-center py-2">
                            <h4 class="mb-0">
                                時光肌®修復療程
                            </h4>
                        </div>
                        <div class="card-text">
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    磨皮效果
                                </div>
                                <div class="col-8 mt-2">
                                    <ul>
                                        <li>效果均勻</li>
                                        <li>磨去30-35微米角質</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    副作用
                                </div>
                                <div class="col-8 mt-2">
                                    <ul>
                                        <li>沒有</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    所需時間
                                </div>
                                <div class="col-8 mt-2">
                                    <ul>
                                        <li>一次過清潔、磨皮、精華導入</li>
                                        <li>快捷省時</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0 border-bottom-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    皮膚類型
                                </div>
                                <div class="col-8 mt-2">
                                    <ul>
                                        <li>適合所有皮膚</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md card mr-md-4 p-0"
                     data-aos="fade-up"
                     data-aos-duration="1000">
                    <div class="card-body">
                        <div class="card-title text-center d-flex flex-column justify-content-center py-2">
                            <h4 class="mb-0">傳統微晶磨皮</h4>
                        </div>
                        <div class="card-text">
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    磨皮效果
                                </div>
                                <div class="col-8 text-justify mt-2">
                                    <ul>
                                        <li>效果不均勻</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    副作用
                                </div>
                                <div class="col-8 text-justify mt-2">
                                    <ul>
                                        <li>療程後皮膚變乾</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    所需時間
                                </div>
                                <div class="col-8 text-justify mt-2">
                                    <ul>
                                        <li>不包括清潔、精華導入</li>
                                        <li>療程後清潔「粉末」需時</li>
                                    </ul>
                                </div>
                            </div>
                            <div class="row m-0 border-bottom-0">
                                <div class="col-4 header text-center px-0 mt-2">
                                    皮膚類型
                                </div>
                                <div class="col-8 text-justify mt-2">
                                    <ul>
                                        <li>不適合缺水、敏感、暗瘡皮膚</li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SilkpeelMethods",

    }
</script>

<style lang="scss" scoped>
    @import "./mixins.scss";
    @import "./variables.scss";

    section {
        &.methods {
            background-image: url("../../../assets/campaign/silkpeel/landing2_methods_bg.png");
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center center;

            & > .container {
                & > h1 {
                    color: rgba(255, 255, 255, 1);
                }

                .cards {
                    .card {
                        .card-body {
                            .card-title {
                                &.featured {
                                    color: rgba(255, 255, 255, 1);
                                    background-color: rgba(145, 221, 237, 1);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
</style>
