<template>
    <section class="intro d-flex justify-content-center align-items-center px-4 py-5">
        <div class="bg-ring d-flex justify-content-center align-items-center">
            <img src="../../../assets/campaign/silkpeel/ring_intro.png" alt="Ring in the background">
        </div>
        <div class="container d-flex justify-content-center align-items-center">
            <div class="introductory-text">
                <h1 class="text-center font-weight-bold mb-4"
                    data-aos="fade"
                    data-aos-duration="1000">
                    全球頂尖時光肌®修復療程
                </h1>
                <h3 class="text-left text-md-center mb-5"
                    data-aos="fade-up"
                    data-aos-duration="1000">
                    雙重技術融入．研發超過30年專利技術
                </h3>
                <div class="mx-0 mx-md-5">
                    <h5 class="text-left text-md-center mb-4"
                        data-aos="fade-up"
                        data-aos-duration="1000">
                        療程採用以獲專利的皇牌時光肌®安瓶，採用10%人體骨髓幹細胞培養液，通過KFDA及FDA雙認證，與人體結構相近。時光肌®擁有高濃度親和性蛋白質，以精煉「皮秒PICO」專利技術，已兆萬之一分子將營養微秒間光速注入肌底，立即啟動肌膚細胞功能和自我修復力，加速新陳代謝，倍效提升彈性，讓肌膚水潤嫩白，仿如坐上時光機一樣，為肌膚重拾幼滑細嫩，充滿剔透光澤感。
                    </h5>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        name: "SilkpeelIntroduction",

    }
</script>

<style lang="scss" scoped>
    @import "./mixins.scss";
    @import "./variables.scss";

    section {
        &.intro {
            position: relative;
            background:
                linear-gradient(
                    0deg, rgba(112, 198, 215, 1) 0%,
                    rgba(178, 234, 246, 1) 95%,
                    rgba(178, 234, 246, 1) 100%
                );
            color: rgba(255, 255, 255, 1);
            overflow: hidden;

            & > .bg-ring {
                position: absolute;
                top: -7.5%;
                left: 0;
                bottom: -7.5%;
                right: 0;

                & > img {
                    height: 100%;
                    width: auto;
                    animation: rotate 60s infinite linear;
                }
            }
        }
    }

    @keyframes rotate {
        from {
            transform: rotate(0deg);
        }
        to {
            transform: rotate(359deg);
        }
    }
</style>
